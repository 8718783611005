import React from "react";
import Loading from "./components/Loading";
import "./App.css";

const App = () => {
  React.useEffect(() => {
    const redirectToDownloadPage = setTimeout(() => {
      // URL for users who have not installed the app
      const url = "https://link-to.app/genwe";
      window.location.href = url;
    }, 3000);

    return () => {
      clearTimeout(redirectToDownloadPage);
    };
  }, []);
  return <Loading />;
};

export default App;
